import { Paper } from '@material-ui/core'
import { makeStyles, styled } from '@material-ui/core/styles'
import grey from '@material-ui/core/colors/grey'

export const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
  filtersContainer: {
    display: 'flex',
    justifyItems: 'center',
    marginBottom: '20px',
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
      alignItems: 'center',
    },
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      alignItems: 'center',
    },
  },
  searchBarContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '20px',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  searchBar: {
    background: '#f2f2f2',
    width: '600px',
    [theme.breakpoints.between('lg', 'xl')]: {
      width: '500px',
    },
    [theme.breakpoints.down('lg')]: {
      width: '380px',
    },
    [theme.breakpoints.down('md')]: {
      width: '200px',
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  formControl: {
    marginTop: 0,
    maxWidth: 100,
    marginBottom: 0,
    marginRight: 100,
    '& .MuiInputBase-formControl': {
      height: 48,
      minWidth: 180,
    },
    '& .MuiSelect-select:focus': {
      backgroundColor: 'transparent',
    },
    [theme.breakpoints.down('xs')]: {
      marginTop: 10,
      marginBottom: 10,
    },
  },
  tablePagination: {
    '& .MuiSelect-select:focus': {
      backgroundColor: 'transparent',
    },
  },
  checkbox: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
    '&.Mui-checked:hover': {
      backgroundColor: 'transparent',
    },
  },
  icon: {
    borderRadius: 3,
    width: 16,
    height: 16,
    backgroundColor: '#f5f8fa',
    border: `1px solid ${theme.palette.grey[400]}`,
  },
  checkedIcon: {
    backgroundColor: theme.palette.primary.main,
    borderRadius: 3,
    '&:before': {
      display: 'block',
      height: 16,
      backgroundImage:
        "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
        " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
        "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
      content: '""',
    },
  },
  categorySectionBackground: {
    backgroundColor: `${theme.palette.primary.main}1A`,
    marginBottom: '20px',
    paddingBottom: '10px',
  },
  subcategoryBackground: {
    backgroundColor: `${theme.palette.primary.main}33`,
  },
  fullWidth: {
    width: '100%',
  },
  dialogTitle: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '8px 12px 0px 24px',
  },
  formControlLabel: {
    borderRadius: '4px',
    margin: '5px',
    padding: '3px 9px 3px 0px',
  },
  menu: {
    '& .MuiMenu-paper': {
      boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
    },
    '& .MuiList-padding': {
      paddingBottom: 0,
    },
    '& .MuiPaper-root': {
      width: 'auto',
      maxHeight: 500,
      maxWidth: '40%',
      [theme.breakpoints.down('sm')]: {
        maxWidth: '80vw',
        height: 'auto',
      },
    },
  },
  stickyButtons: {
    position: 'sticky',
    bottom: 0,
    background: 'white',
    padding: '10px 24px',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  flexDisplay: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
}))

export const MenuPaper = styled(Paper)({
  border: `1px solid ${grey[300]}`,
  boxShadow: `1px 1px 12px 0 ${grey[400]}`,
})
