import { makeStyles } from '@material-ui/core/styles'
import grey from '@material-ui/core/colors/grey'

export const useStyles = makeStyles((theme) => ({
  paperShadow: {
    border: `1px solid ${grey[300]}`,
    boxShadow: `1px 1px 12px 0 ${grey[400]}`,
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(0 ,1.5)
  },
}))
