import React, { useEffect, useState } from 'react'
import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Box,
  IconButton,
} from '@material-ui/core'
import { Translate } from 'react-localize-redux'
import PropTypes from 'prop-types'
import CloseIcon from '@material-ui/icons/Close'
import { useStyles } from '../styles'

const CategoriesDialog = ({
  isOpen,
  onClose,
  options,
  selectedOptions,
  onCategoryChange,
}) => {
  const classes = useStyles()

  const [tempSelectedOptions, setTempSelectedOptions] = useState([
    ...selectedOptions,
  ])

  useEffect(() => {
    setTempSelectedOptions([...selectedOptions])
  }, [selectedOptions])

  const handleToggle = (value) => () => {
    const currentIndex = tempSelectedOptions.indexOf(value)
    const newChecked = [...tempSelectedOptions]

    if (currentIndex === -1) {
      newChecked.push(value)
    } else {
      newChecked.splice(currentIndex, 1)
    }

    setTempSelectedOptions(newChecked)
  }

  const handleSave = () => {
    onCategoryChange(tempSelectedOptions)
    onClose()
  }

  const handleReset = () => {
    onCategoryChange([])
    onClose()
  }

  const renderCategories = (categories, level = 1) => {
    return categories.map((category) => (
      <div
        key={category.uuid}
        className={`${level === 1 ? classes.categorySectionBackground : ''} ${
          level === 2 ? classes.fullWidth : ''
        }`}
      >
        <FormControlLabel
          className={`${classes.formControlLabel} ${
            level > 1 ? classes.subcategoryBackground : ''
          }`}
          control={
            <Checkbox
              checked={tempSelectedOptions.includes(category.uuid)}
              onChange={handleToggle(category.uuid)}
              value={category.uuid}
              className={classes.checkbox}
              checkedIcon={
                <span className={`${classes.icon} ${classes.checkedIcon}`} />
              }
              icon={<span className={classes.icon} />}
            />
          }
          label={category.title}
        />
        {category.relationships && category.relationships.children && (
          <div
            className={classes.flexDisplay}
            style={{
              paddingLeft: level === 2 ? `${level * 10}px` : `${level * 20}px`,
            }}
          >
            {renderCategories(category.relationships.children, level + 1)}
          </div>
        )}
      </div>
    ))
  }

  return (
    <>
      <Dialog open={isOpen} onClose={onClose} maxWidth="lg">
        <DialogTitle disableTypography className={classes.dialogTitle}>
          <Translate id="training.select-filters" />
          <IconButton aria-label="close" onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Box>
            <FormGroup>{renderCategories(options)}</FormGroup>
          </Box>
        </DialogContent>
        <DialogActions className={classes.stickyButtons}>
          <Button variant="contained" onClick={handleReset}>
            <Translate id="button.reset" />
          </Button>
          <Button color="primary" variant="contained" onClick={handleSave}>
            <Translate id="button.save" />
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

CategoriesDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      uuid: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
    })
  ).isRequired,
  selectedOptions: PropTypes.arrayOf(PropTypes.string).isRequired,
  onCategoryChange: PropTypes.func.isRequired,
}

export default CategoriesDialog
