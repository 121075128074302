import moment from 'moment-timezone'
import globalTranslations from 'translations/global.json'
import { getLocale } from './localstorage'
import timezonelist from 'constants/timezonelist'

export const EUR_STANDARD = 'MM/dd/yy'
export const SERVER_DATE = 'YYYY-MM-DD'
export const SERVER_DATETIME = 'YYYY-MM-DD HH:mm:ss'
export const SERVER_DATETIME_WITHOUT_SECOND = 'YYYY-MM-DD HH:mm'

export const DATE_FORMAT=[
  'dd.MM.yy',
  'MM/dd/yy',
  'dd/MM/yy',
]

// #important Used for Utc date string converts getting from v2 api's
// *******START*********
export const formatUtcDateToDateTime = (date,timezone) => [
  moment.utc(date).tz(timezone).format(SERVER_DATE),
  moment.utc(date).tz(timezone).format('HH:mm'),
]
export const formatUtcDateTimeToUserTimezoneDateTime = (date,timezone) =>
  moment.utc(date).tz(timezone).format(SERVER_DATETIME)

export const formatDateTimeToUtcDateTimeWithUserTimezone = (date,timezone) => {
  // Parse the input date with the user's timezone
  const localDateTime = moment(date).tz(timezone,true);

  // Format the UTC time to the desired format
  const utcDateTime = localDateTime.utc().format(SERVER_DATETIME);

  return utcDateTime;
};

// *************END*********


export const formatLocalDateTimeToUserTimezoneDateTime = (date,timezone) => {
  const localDateTime = moment(date);
  const userTimezoneDateTime = moment.tz(localDateTime, timezone).format(SERVER_DATETIME);

  return userTimezoneDateTime;
};

// @todo remove when method formatDateFromServer replaced by formatUtcDateToDateTime and api's migrated to v2
export const formatDateFromServer = date => [
  moment(date).format(SERVER_DATE),
  moment(date).format('HH:mm'),
]

// @todo remove when method formatDateTimeToServer replaced by formatUtcDateTimeToUserTimezoneDateTime and api's migrated to v2
export const formatDateTimeToServer = date =>
  moment(date).format(SERVER_DATETIME)

export const formatCostumDateForUI = (date, format = 'DD.MM.YY') => {
  return moment(date).format(format.replace('dd','DD'))
}

export const formatDateForServer = date => moment(date).format(SERVER_DATE)

export const formatDateToServer = (date, time) =>
  moment(`${date} ${time}`, 'YYYY-MM-DD HH:mm').format(SERVER_DATETIME)

export const formatDateTimeToServerWithoutSec = date =>
  moment(date).format(SERVER_DATETIME_WITHOUT_SECOND)

export const formatCostumDate = (date, format = SERVER_DATE) => {
  return moment(date).format(format)
}
export const formatCostumTime = (date, format = 'HH:mm') => {
  return moment(date).format(format)
}

export const formatCostumDateTimeForUI = (date, format = 'DD.MM.YY HH:mm:ss') => {
  return moment(date).format(format)
}
export const formatCostumDateMonthNoForUI = (date, format = 'DD.MM.YY') => {
  return moment(date).format(format.replace('dd','DD'))
}
export const formateDate = date => {
  return [
    moment(date).format('YYYY'), // full year = 2020, 0
    moment(date).format('YY'), // year = 20, 1
    moment(date).format('MM'), // month =01, 2
    moment(date).format('MMM'), // month =Jan, 3
    moment(date).format('DD'), // day 1, 4
    moment(date).format('HH'), // 24hr = 13 / 1PM, 5
    moment(date).format('mm'), // minute = 22, 6
    moment(date).format('ss'), // seconds = 20, 7
    moment(date).format('dddd'), // dayname = Wednesday, 8
  ]
}
export const displayDateForEventPage = date => {

  return `${moment(date).format('DD')} ${moment(date).format('MMM')} ${moment(date).format('YYYY')}`
}

export const display24HrTime = date => {

  return `${moment(date).format('HH')}:${moment(date).format('mm')}`
}
export const getDayName = date => moment(date).format('dddd')


export const diffDate = (date, secondDate) => {
  const a = moment(date, SERVER_DATE)
  const b = moment(secondDate, SERVER_DATE)

  return a.diff(b, 'days')
}

export const checkIsStartDateBeforeEndDate = (startDate, endDate) => {
  const mStart = moment(startDate);
  const mEnd = moment(endDate);

  return mStart.isBefore(mEnd);
}

export const sortByDate = array =>
  array.sort((a, b) => moment(a.date).format('X') - moment(b.date).format('X'))

export const filterDataFromToday = array =>
  array.filter(p => moment(p.date) >= moment())

export const filterDataByDate = (array, date) =>
  array.filter(p => {
    const elementDate = moment(p.date).format(SERVER_DATE)
    const filterDate = moment(date).format(SERVER_DATE)

    return elementDate === filterDate
  })

export const filterDataByDateAndTrainingOrMatch = (array, date, relation) => {
  const filterDate = moment(date).format(SERVER_DATE)

  return array.filter(p => {
    const elementDate = moment(p.date).format(SERVER_DATE)

    return elementDate === filterDate && p.relation === relation
  })
}

export const findIndexForCurrentDate = array =>
  array.findIndex(p => new Date(p.date) >= new Date())

export const getPastOrFutureCount = array => {
  return {
    total: array.length,
    past: array.filter(p => new Date(p.date) < new Date()).length,
    future: array.filter(p => new Date(p.date) >= new Date()).length,
  }
}

export const isDateTodayOrAfterToday = date => new Date(date) >= new Date()

export const subTimeForDatetime = (date, h, m) => {
  date.setHours(date.getHours() - h)
  date.setMinutes(date.getMinutes() - m)

  return moment(date).format(SERVER_DATETIME)
}
export const convertZoneTimeToLocalTimeZone = (date, zone='Europe/Berlin', formate=SERVER_DATETIME) => moment.tz(date,zone).local().format(formate)

export const currentDatetimeOfUserTimezone=(zone)=>{
  const currentTime=moment.tz(new Date(), moment.tz.guess())
  if (zone && timezonelist.includes(zone)){
    return currentTime.tz(zone).format(SERVER_DATETIME)
  }
  // returing current system time
  
  return currentTime.format(SERVER_DATETIME)
}
export const currentDateFormat=()=>{
  const currentTime=moment.tz(new Date(), moment.tz.guess())
  currentTime.day();
  currentTime.month()
}

const durationTranslate=(type)=>{
  const lang=getLocale();
  const ticker=globalTranslations.ticker;

  let finalStr="";

  switch (type) {
    case 'sec':
      switch (lang) {
        case 'de':
          finalStr=ticker.second[1]
          break;
        case 'es':
          finalStr=ticker.second[2]
          break;
        case 'it':
          finalStr=ticker.second[3]
          break;
      
        default:
          finalStr=ticker.second[0]
          break;
      }
      break;
    case 'min':
      switch (lang) {
        case 'de':
          finalStr=ticker.minute[1]
          break;
        case 'es':
          finalStr=ticker.minute[2]
          break;
        case 'it':
          finalStr=ticker.minute[3]
          break;
      
        default:
          finalStr=ticker.minute[0]
          break;
      }
      break;
    case 'hr':
      switch (lang) {
        case 'de':
          finalStr=ticker.hour[1]
          break;
        case 'es':
          finalStr=ticker.hour[2]
          break;
        case 'it':
          finalStr=ticker.hour[3]
          break;
      
        default:
          finalStr=ticker.hour[0]
          break;
      }
      break;
    case 'day':
      switch (lang) {
        case 'de':
          finalStr=ticker.day[1]
          break;
        case 'es':
          finalStr=ticker.day[2]
          break;
        case 'it':
          finalStr=ticker.day[3]
          break;
        default:
          finalStr=ticker.day[0]
          break;
      }
      break;
    case 'mon':
      switch (lang) {
        case 'de':
          finalStr=ticker.month[1]
          break;
        case 'es':
          finalStr=ticker.month[2]
          break;
        case 'it':
          finalStr=ticker.month[3]
          break;
      
        default:
          finalStr=ticker.month[0]
          break;
      }
      break;
  
    case 'yr':
      switch (lang) {
        case 'de':
          finalStr=ticker.year[1]
          break;
        case 'es':
          finalStr=ticker.year[2]
          break;
        case 'it':
          finalStr=ticker.year[3]
          break;
      
        default:
          finalStr=ticker.year[0]
          break;
      }
      break;
  
    default:
      break;
  }

  return finalStr
}


export const  timeSince=(date,zone='Europe/Berlin')=> {
  

  const nowTime=new Date(currentDatetimeOfUserTimezone(zone));
  const seconds = Math.floor(nowTime - date) / 1000;

  let interval = seconds / 31536000;

  if (interval > 1) {
    return `${Math.floor(interval)  } ${durationTranslate('yr')}`;
  }
  interval = seconds / 2592000;
  if (interval > 1) {
    return `${Math.floor(interval)  } ${durationTranslate('mon')}`;
  }
  interval = seconds / 86400;
  if (interval > 1) {
    return `${Math.floor(interval)  } ${durationTranslate('day')}`;
  }
  interval = seconds / 3600;
  if (interval > 1) {
    return `${Math.floor(interval)  } ${durationTranslate('hr')}`;
  }
  interval = seconds / 60;
  if (interval > 1) {
    return `${Math.floor(interval)  } ${durationTranslate('min')}`;
  }
  
  return `${Math.floor(seconds)  } ${durationTranslate('sec')}`;
}

export const getPastEvents = array => {
  return array.filter(p => new Date(p.date) < new Date())
}

export const concatDateTime = (date, time)=>{
  return `${moment(date).format("YYYY-MM-DD")} ${time}`
}

/**
 * Concatenates the date from the first parameter and the time from the second parameter,
 * both in the provided user timezone, and then converts it to UTC format.
 * 
 * @param {string} date1 - The first date string in any valid format.
 * @param {string} date2 - The second date string in any valid format.
 * @param {string} timezone - The user timezone in which the dates are interpreted.
 * @returns {string} - UTC date time string with YYYY-MM-DD HH:mm:ss format.
 */
export const concatDateAndTimeOfTwoDatesWithUserTimezoneAndUtcFormat = (date1, date2,timezone) => {

  // Fetch date from 1st date
  const datePart = moment(date1).format('YYYY-MM-DD');

  // Parse the second date (time) as local time so that it will also use timezone
  const localTime = moment(date2).format('HH:mm:ss');

  // Concatenate date and time
  const combinedDateTime = moment(`${datePart} ${localTime}`).tz(timezone,true);

  // Convert combined date and time to UTC
  const utcDateTime = moment.utc(combinedDateTime).format(SERVER_DATETIME);

  return utcDateTime;
};

export const getHourMinute = (date)=>{
  return `${moment(date).format("HH:mm")}`
}

/**
 * Check if first date time is after 2nd date time
 * Params: DateTime1, DateTime2 
 */
export const checkIsAfterDateTime=((date1,date2)=>{
  return moment(date1).isAfter(date2)
})

// returns formatted date like: 18 Jul 2024 Thursday
export const formatFullDateWithDayAndUserDateFormat = (date,format = 'DD.MM.YY') => {
  return `${moment(date).format(`${format.replace('dd','DD')} dddd`)}`
}

export const getWeekStartAndEndByDate = (date) => {
  const startOfWeek = moment(date).startOf('isoWeek').format(SERVER_DATETIME)
  const endOfWeek = moment(date).endOf('isoWeek').format(SERVER_DATETIME)

  return { startOfWeek, endOfWeek }
}